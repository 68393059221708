import { SxProps, Theme } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import family from 'app/assets/images/homePage/family.webp'
import photography from 'app/assets/images/homePage/photography.webp'
import relax from 'app/assets/images/homePage/relax.webp'
import { useIsMobile } from 'utils/hooks/useIsMobile'

export const ForWhom = () => {
  const isMobile = useIsMobile()
  const imageSize = '200px'
  const avatarSx: SxProps<Theme> = (theme) => ({
    width: imageSize,
    height: imageSize,
    color: theme.palette.primary.main,
  })

  return (
    <Box py={4}>
      <Box mb={4}>
        <Typography fontWeight="bold" align="center">
          Dla kogo?
        </Typography>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr' }}
        columnGap={8}
        rowGap={4}
        justifyItems="center"
      >
        <Avatar sx={(theme) => ({ ...avatarSx(theme), order: 1 })}>
          <img src={family} alt={'family'} width={imageSize} height={imageSize} />
        </Avatar>
        <Avatar sx={(theme) => ({ ...avatarSx(theme), order: 3 })}>
          <img src={photography} alt={'family'} width={imageSize} height={imageSize} />
        </Avatar>
        <Avatar sx={(theme) => ({ ...avatarSx(theme), order: 5 })}>
          <img src={relax} alt={'family'} width={imageSize} height={imageSize} />
        </Avatar>
        <Box sx={{ order: isMobile ? 2 : 6 }}>
          <Typography align="center">Dla rodzin, które chcą spędzić czas razem w otoczeniu przyrody.</Typography>
        </Box>
        <Box sx={{ order: isMobile ? 4 : 7 }}>
          <Typography align="center">Dla miłośników fotografowania przyrody i dzikiego życia.</Typography>
        </Box>
        <Box sx={{ order: isMobile ? 6 : 8 }}>
          <Typography align="center">
            Dla osób pragnących oderwać się od miejskiego hałasu i odprężyć się w naturalnym otoczeniu.
          </Typography>
        </Box>
      </Box>
      <Box mt={4}>
        <Typography>
          Przyjedź do "U progu lasu" i poczuj magię Puszczy Białowieskiej. Nasza agroturystyka to miejsce, gdzie czas
          zwalnia, a przyroda jest w pełni dostępna. Spędź u nas niezapomniany wypoczynek, który pozostawi w tobie
          piękne wspomnienia.
        </Typography>
      </Box>
    </Box>
  )
}
