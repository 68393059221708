import kuchnia1 from './inside/kuchnia-1.jpg'
import kuchnia2 from './inside/kuchnia-2.jpg'
import lustro from './inside/lustro.jpg'
import posciel from './inside/posciel.jpg'
import wejscie_pokoj from './inside/wejscie-pokoj.jpg'
import wejscie_pokoj_2 from './inside/wejscie-pokoj-2.jpg'
import wejscie_pokoj_3 from './inside/wejscie-pokoj-3.jpg'
import zakaz_palenia from './inside/zakaz-palenia.jpg'
import altanka_1 from './outside/altanka-1.jpg'
import altanka_2 from './outside/altanka-2.jpg'
import balia_2 from './outside/balia-2.jpg'
import taras_1 from './outside/taras-1.jpg'
import taras_2 from './outside/taras-2.jpg'
import taras_balia from './outside/taras-balia.jpg'
import wejscie_glowne from './outside/wejscie-glowne.jpg'
import pokoj_1_1 from './room1/pokoj-1-1.jpg'
import pokoj_1_2 from './room1/pokoj-1-2.jpg'
import pokoj_1_3 from './room1/pokoj-1-3.jpg'
import pokoj_1_4 from './room1/pokoj-1-4.jpg'
import pokoj_1_5 from './room1/pokoj-1-5.jpg'
import pokoj_2_1 from './room2/pokoj-2-1.jpg'
import pokoj_2_2 from './room2/pokoj-2-2.jpg'
import pokoj_2_3 from './room2/pokoj-2-3.jpg'
import pokoj_2_4 from './room2/pokoj-2-4.jpg'
import pokoj_2_5 from './room2/pokoj-2-5.jpg'
import pokoj_3_1 from './room3/pokoj-3-1.jpg'
import pokoj_3_2 from './room3/pokoj-3-2.jpg'
import pokoj_3_3 from './room3/pokoj-3-3.jpg'
import pokoj_3_4 from './room3/pokoj-3-4.jpg'
import pokoj_3_5 from './room3/pokoj-3-5.jpg'
import aquapark from './attractionsInRegion/aquapark.jpg'
import drezyny from './attractionsInRegion/drezyny.jpg'
import kolejka from './attractionsInRegion/kolejka.jpeg'
import palac from './attractionsInRegion/palac.jpeg'
import park from './attractionsInRegion/park.jpeg'
import puszcza from './attractionsInRegion/puszcza.jpg'
import rowery from './attractionsInRegion/rowery.jpeg'
import topilo from './attractionsInRegion/topilo.jpg'
import regulation1 from './regulations/regulamin1.jpeg'
import regulation2 from './regulations/regulamin2.jpeg'

type IMG = {
  label?: string
  src: MediaSource
  alt: string
  orientation: 'horizontal' | 'vertical'
}
export const images: Record<
  'inside' | 'outside' | 'room1' | 'room2' | 'room3' | 'attractionsInRegion' | 'regulations',
  IMG[]
> = {
  inside: [
    {
      label: 'Kuchnia',
      src: kuchnia1,
      alt: 'kuchnia1',
      orientation: 'horizontal',
    },
    {
      label: 'Kuchnia',
      src: kuchnia2,
      alt: 'kuchnia2',
      orientation: 'vertical',
    },
    {
      label: 'Hol',
      src: lustro,
      alt: 'lustro',
      orientation: 'vertical',
    },
    {
      label: 'Pokój',
      src: posciel,
      alt: 'posciel',
      orientation: 'horizontal',
    },
    {
      label: 'Hol',
      src: wejscie_pokoj,
      alt: 'wejscie-pokoj',
      orientation: 'vertical',
    },
    {
      label: 'Hol',
      src: wejscie_pokoj_2,
      alt: 'wejscie-pokoj-2',
      orientation: 'horizontal',
    },
    {
      label: 'Hol',
      src: wejscie_pokoj_3,
      alt: 'wejscie-pokoj-3',
      orientation: 'horizontal',
    },
    {
      label: 'Hol',
      src: zakaz_palenia,
      alt: 'zakaz-palenia',
      orientation: 'vertical',
    },
  ],
  outside: [
    {
      label: 'Ogród',
      src: altanka_1,
      alt: 'altanka1',
      orientation: 'vertical',
    },
    {
      label: 'Ogród',
      src: altanka_2,
      alt: 'altanka2',
      orientation: 'horizontal',
    },
    {
      label: 'Ogród',
      src: balia_2,
      alt: 'balia_2',
      orientation: 'vertical',
    },
    {
      label: 'Ogród',
      src: taras_1,
      alt: 'taras_1',
      orientation: 'vertical',
    },
    {
      label: 'Ogród',
      src: taras_2,
      alt: 'taras_2',
      orientation: 'horizontal',
    },
    {
      label: 'Ogród',
      src: taras_balia,
      alt: 'taras_balia',
      orientation: 'vertical',
    },
    {
      label: 'Ogród',
      src: wejscie_glowne,
      alt: 'wejscie_glowne',
      orientation: 'vertical',
    },
  ],
  room1: [
    {
      label: 'Pokój pierwszy',
      src: pokoj_1_1,
      alt: 'pokoj_1_1',
      orientation: 'vertical',
    },
    {
      label: 'Pokój pierwszy',
      src: pokoj_1_2,
      alt: 'pokoj_1_2',
      orientation: 'horizontal',
    },
    {
      label: 'Pokój pierwszy',
      src: pokoj_1_3,
      alt: 'pokoj_1_3',
      orientation: 'horizontal',
    },
    {
      label: 'Pokój pierwszy',
      src: pokoj_1_4,
      alt: 'pokoj_1_4',
      orientation: 'horizontal',
    },
    {
      label: 'Pokój pierwszy',
      src: pokoj_1_5,
      alt: 'pokoj_1_5',
      orientation: 'vertical',
    },
  ],

  room2: [
    {
      label: 'Pokój drugi',
      src: pokoj_2_1,
      alt: 'pokoj_2_1',
      orientation: 'vertical',
    },
    {
      label: 'Pokój drugi',
      src: pokoj_2_2,
      alt: 'pokoj_2_2',
      orientation: 'vertical',
    },
    {
      label: 'Pokój drugi',
      src: pokoj_2_3,
      alt: 'pokoj_2_3',
      orientation: 'vertical',
    },
    {
      label: 'Pokój drugi',
      src: pokoj_2_4,
      alt: 'pokoj_2_4',
      orientation: 'horizontal',
    },
    {
      label: 'Pokój drugi',
      src: pokoj_2_5,
      alt: 'pokoj_2_5',
      orientation: 'horizontal',
    },
  ],

  room3: [
    {
      label: 'Pokój trzeci',
      src: pokoj_3_1,
      alt: 'pokoj_3_1',
      orientation: 'horizontal',
    },
    {
      label: 'Pokój trzeci',
      src: pokoj_3_2,
      alt: 'pokoj_3_2',
      orientation: 'vertical',
    },
    {
      label: 'Pokój trzeci',
      src: pokoj_3_3,
      alt: 'pokoj_3_3',
      orientation: 'vertical',
    },
    {
      label: 'Pokój trzeci',
      src: pokoj_3_4,
      alt: 'pokoj_3_4',
      orientation: 'vertical',
    },
    {
      label: 'Pokój trzeci',
      src: pokoj_3_5,
      alt: 'pokoj_3_5',
      orientation: 'vertical',
    },
  ],

  attractionsInRegion: [
    {
      label: 'aquapark',
      src: aquapark,
      alt: 'aquapark',
      orientation: 'horizontal',
    },
    {
      label: 'drezyny',
      src: drezyny,
      alt: 'drezyny',
      orientation: 'vertical',
    },
    {
      label: 'kolejka',
      src: kolejka,
      alt: 'kolejka',
      orientation: 'vertical',
    },
    {
      label: 'palac',
      src: palac,
      alt: 'palac',
      orientation: 'vertical',
    },
    {
      label: 'park',
      src: park,
      alt: 'park',
      orientation: 'vertical',
    },
    {
      label: 'puszcza',
      src: puszcza,
      alt: 'puszcza',
      orientation: 'vertical',
    },
    {
      label: 'rowery',
      src: rowery,
      alt: 'rowery',
      orientation: 'vertical',
    },
    {
      label: 'topilo',
      src: topilo,
      alt: 'topilo',
      orientation: 'vertical',
    },
  ],
  regulations: [
    {
      label: 'regulamin 1',
      src: regulation1,
      alt: 'regulamin1',
      orientation: 'vertical',
    },
    {
      label: 'regulamin 2',
      src: regulation2,
      alt: 'regulamin2',
      orientation: 'vertical',
    },
  ],
}
