import Box from '@mui/material/Box'
import Carousel from 'react-material-ui-carousel'

type Props = {
  items: { url: MediaSource; alt: string }[]
  height?: number
}

export const MediaCarousel = ({ height = 400, items }: Props) => {
  return (
    <Carousel fullHeightHover={false} height={height} navButtonsAlwaysVisible>
      {items.map((item) => (
        <Box display="grid" justifyItems="center" alignContent="center" height="100%" key={item.alt}>
          <img
            src={String(item.url)}
            alt={item.alt}
            style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }}
            loading="lazy"
          />
        </Box>
      ))}
    </Carousel>
  )
}
