import Container from '@mui/material/Container'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { images } from 'app/assets/images'
import { useIsMobile } from 'utils/hooks/useIsMobile'

function srcset(image: MediaSource, width: number, height: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`,
  }
}

export const Gallery = () => {
  const isMobile = useIsMobile()

  return (
    <Container maxWidth="xl">
      <ImageList
        sx={{
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)',
          gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))!important',
        }}
        variant="masonry"
        cols={isMobile ? 2 : 3}
        gap={8}
      >
        {Object.entries({
          inside: images.inside,
          outside: images.outside,
          room1: images.room1,
          room2: images.room2,
          room3: images.room3,
        }).map(([name, imagesPerPosition]) =>
          imagesPerPosition.map((item) => {
            const cols = item.orientation === 'horizontal' ? 5 : 3
            const rows = item.orientation === 'horizontal' ? 3 : 5
            return (
              <ImageListItem key={item.alt} sx={{ height: '100% !important' }}>
                <img {...srcset(item.src, 250, 200, rows, cols)} alt={item.alt} loading="lazy" />
                <ImageListItemBar
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                  }}
                  title={isMobile ? undefined : item.label}
                  position="top"
                />
              </ImageListItem>
            )
          })
        )}
      </ImageList>
    </Container>
  )
}
