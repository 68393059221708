import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { ForWhom } from 'app/layout/ForWhom/ForWhom'
import { Gallery } from 'app/layout/Gallery/Gallery'
import { InPlace } from 'app/layout/InPlace/InPlace'
import { useSetTitle } from 'utils/hooks/useSetTitle'
import { standardBoxProps } from 'utils/styles/standardBoxProps'

export const HomePage = () => {
  useSetTitle()
  return (
    <>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          [theme.breakpoints.up('xl')]: {
            height: '50vh',
          },
          height: '100%',
          minHeight: '50vh',
          background: '#fff',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Typography mb={2} fontWeight="bold" align="center">
            Agroturystyka "U progu lasu" - Twój Raj w Puszczy Białowieskiej
          </Typography>
          <Typography mb={2}>
            Zapraszamy Cię do wyjątkowego miejsca, gdzie natura i spokój spotykają się w doskonałej harmonii. Nasza
            agroturystyka znajduje się zaledwie kilka kroków od majestatycznej Puszczy Białowieskiej, jednego z
            najcenniejszych skarbów przyrodniczych Europy. To idealne miejsce dla miłośników przyrody, którzy pragną
            oderwać się od codziennego zgiełku i zanurzyć się w dzikim pięknie.
          </Typography>
          <Typography>
            Przytulne pokoje, które oferują komfortowy i przyjazny wypoczynek. Położenie blisko lasu sprawia, że każdego
            dnia budzisz się w otoczeniu śpiewu ptaków i szumienia drzew. Pokoje gościnne zachowują tradycyjny
            charakter, ale są wyposażone w nowoczesne udogodnienia.
          </Typography>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '100vh',
          background: '#f2f4ec',
        })}
      >
        <Container maxWidth="xl">
          <Gallery />
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '50vh',
          background: '#fff',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="md">
          <InPlace />
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '50vh',
          background: '#f2f4ec',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <ForWhom />
        </Container>
      </Box>
    </>
  )
}
