export const pages = [
  {
    label: 'Strona Główna',
    link: '/',
  },
  {
    label: 'Pokoje',
    link: '/pokoje',
  },
  {
    label: 'Atrakcje',
    link: '/atrakcje',
  },
  {
    label: 'Galeria',
    link: '/galeria',
  },
  // {
  //   label: 'Kontakt',
  //   link: '#Kontakt',
  // },
  {
    label: 'Regulamin',
    link: '/regulamin',
  },
]
