import Facebook from '@mui/icons-material/Facebook'
import Instagram from '@mui/icons-material/Instagram'
import MenuIcon from '@mui/icons-material/Menu'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useNavigate } from '@tanstack/react-router'
import { TikTok } from 'app/assets/icons/TikTok'
import { Logo } from 'app/layout/nav/Logo'
import { pages } from 'pages/pages'
import { MouseEvent, useEffect, useState } from 'react'

type Props = {
  inverted?: boolean
}

export const Navbar = ({ inverted }: Props) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const socials = [
    {
      label: 'Facebook',
      icon: <Facebook />,
      link: 'https://www.facebook.com/UPROGULASU',
    },
    {
      label: 'Instagram',
      icon: <Instagram />,
      link: 'https://www.instagram.com/uprogulasu/',
    },
    {
      label: 'TiKTok',
      icon: <TikTok />,
      link: 'https://www.tiktok.com/@uprogulasu',
    },
  ]
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorElNav)
  const fontColor = inverted ? 'black' : 'white'
  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = (link?: string) => () => {
    setAnchorElNav(null)
    if (link && link.includes('http')) {
      window.open(link, '_blank')
    } else {
      navigate({ to: link }).then(() => window.scrollTo({ top: screenHeight, left: 0, behavior: 'smooth' }))
    }
  }

  return (
    <Container maxWidth="xl" sx={{ minHeight: 80 }}>
      <Toolbar disableGutters sx={{ height: 80 }}>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-controls={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenNavMenu}
            sx={{ color: fontColor }}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={open}
            onClose={() => handleCloseNavMenu()}
            onClick={() => handleCloseNavMenu()}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {[...pages, ...socials].map((page) => (
              <MenuItem key={page.label} onClick={handleCloseNavMenu(page.link)}>
                <Typography textAlign="center">{page.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Logo inverted={inverted} />
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'center' } }}>
          {pages.map((page) => (
            <Button
              key={page.label}
              onClick={handleCloseNavMenu(page.link)}
              sx={{ my: 2, color: fontColor, display: 'block' }}
            >
              {page.label}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          {socials.map((social) => (
            <Tooltip title={social.label} key={social.label}>
              <IconButton onClick={handleCloseNavMenu(social.link)} sx={{ my: 2, color: fontColor, display: 'block' }}>
                {social.icon || social.label}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      </Toolbar>
    </Container>
  )
}
