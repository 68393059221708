import { RouterProvider } from '@tanstack/react-router'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { router2 } from 'routes/router2'
import theme from './theme'
import './fonts.css'
// https://ninetheme.com/themes/forester/#services

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router2} />
      <ToastContainer />
    </ThemeProvider>
  )
}

export default App
