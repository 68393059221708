import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { images } from 'app/assets/images'
import { MediaCarousel } from 'app/layout/Carousel/MediaCarousel'
import { useIsMobile } from 'utils/hooks/useIsMobile'
import { useSetTitle } from 'utils/hooks/useSetTitle'
import { standardBoxProps } from 'utils/styles/standardBoxProps'

export const RoomsPage = () => {
  useSetTitle()
  const isMobile = useIsMobile()

  const items1 = images.room1.map((room) => ({ url: room.src, alt: room.alt }))
  const items2 = images.room2.map((room) => ({ url: room.src, alt: room.alt }))
  const items3 = images.room3.map((room) => ({ url: room.src, alt: room.alt }))

  return (
    <>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '75vh',
          background: '#fff',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center" spacing={{ xs: 2, md: 16 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">Pokój pierwszy</Typography>
              <Typography fontSize={isMobile ? 14 : 16}>
                Pokój ten jest idealny zarówno dla par, które pragną spędzić wyjątkowy czas we dwoje w otoczeniu
                przyrody i spokoju, jak i jednej osoby. Duże, wygodne łóżko zapewni spokojny sen, a własna łazienka
                gwarantuje prywatność. Pokój posiada w swoim wyposażeniu telewizor z dostępem do internetu, co umożliwia
                odtworzenie ulubionej muzyki lub filmu po długim dniu na świeżym powietrzu. Wnętrze jest urządzone w
                jasnych kolorach, z dodatkami drewna i kwiatów, tworząc przytulną atmosferę. W pokoju znajduje się
                również stolik i krzesła, doskonałe do pracy na laptopie lub spożywania posiłków.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MediaCarousel items={items1} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '75vh',
          background: '#f2f4ec',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center" spacing={{ xs: 2, md: 16 }}>
            {!isMobile && (
              <Grid item xs={12} md={6}>
                <MediaCarousel items={items2} />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Typography variant="h4">Pokój drugi</Typography>
              <Typography fontSize={isMobile ? 14 : 16}>
                Ten przytulny pokój to idealne miejsce dla rodziny lub grupy przyjaciół, którzy pragną spędzić
                relaksujący czas na wsi. Oferuje on własną łazienkę, która zapewnia prywatność. Znajdują się w nim trzy
                pojedyncze, wygodne łóżka. Wnętrzne pokoju jest jasne, z motywem drewna i kwiatów, co tworzy przyjemną
                atmosferę. Duże okna do pomieszczenia wpada wiele naturalnego światła, szczególnie pięknie jest podczas
                zachodu słońca. Wnętrze jest przestronne, ale również przytulne, co pozwoli każdemu gościowi poczuć się
                swobodnie.
              </Typography>
            </Grid>
            {isMobile && (
              <Grid item xs={12} md={6}>
                <MediaCarousel items={items2} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>

      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '75vh',
          background: '#fff',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" alignItems="center" spacing={{ xs: 2, md: 16 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4">Pokój trzeci</Typography>
              <Typography fontSize={isMobile ? 14 : 16}>
                Jest to idealne miejsce dla grupy przyjaciół lub rodziny, którzy pragną spędzić wspólny czas w
                komfortowych warunkach w sercu przyrody. Ten przestronny pokój oferuje trzy wygodne pojedyncze łóżka, co
                sprawia, że jest doskonały zarówno dla trzech osób, jak i dla par podróżujących z dzieckiem. Wnętrze
                pokoju jest urządzone w przyjaznych, ciepłych kolorach, które tworzą przytulną atmosferę. Własna
                łazienka jest nowoczesna i wyposażona we wszelkie niezbędne udogodnienia.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <MediaCarousel items={items3} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
