import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { images } from 'app/assets/images'
import { useSetTitle } from 'utils/hooks/useSetTitle'
import { standardBoxProps } from 'utils/styles/standardBoxProps'

export const RegulationsPage = () => {
  useSetTitle()
  const theme = useTheme()

  return (
    <>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '100vh',
          background: '#f2f4ec',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Box display="grid" sx={{ mb: 2 }}>
            <Typography variant="h4" align="center">
              Regulamin:
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {images.regulations.map((img) => (
              <Grid item xs={12} md={6} key={img.alt}>
                <img
                  src={String(img.src)}
                  alt={img.alt}
                  style={{ width: '100%', border: `1px solid ${theme.palette.secondary.main}` }}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  )
}
