import { useLocation } from '@tanstack/react-router'
import { pages } from 'pages/pages'
import { useEffect } from 'react'

export const useSetTitle = (title = '') => {
  const location = useLocation()
  const label = location.pathname === '/' ? null : pages.find((page) => page.link === location.pathname)?.label
  useEffect(() => {
    document.title = `${label ? label + ' | ' : ''}Agroturystyka U progu lasu | Puszcza Białowieska | Podlasie`
  })
}
