import { Gallery } from 'app/layout/Gallery/Gallery'
import { useSetTitle } from 'utils/hooks/useSetTitle'

export const GalleryPage = () => {
  useSetTitle()
  return (
    <>
      <Gallery />
    </>
  )
}
