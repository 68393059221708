export const standardBoxProps = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  textShadow: '1px 1px 1px black',
  p: 2,
}
