import LocalParking from '@mui/icons-material/LocalParking'
import Nightlife from '@mui/icons-material/Nightlife'
import LocalFlorist from '@mui/icons-material/LocalFlorist'
import Deck from '@mui/icons-material/Deck'
import Kitchen from '@mui/icons-material/Kitchen'
import Bed from '@mui/icons-material/Bed'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ReactNode } from 'react'

export const InPlace = () => {
  const Dot = ({ icon }: { icon?: ReactNode }) => (
    <TimelineDot sx={{ backgroundColor: (theme) => theme.palette.divider }} children={icon} />
  )
  const Connector = () => <TimelineConnector sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }} />

  return (
    <Box>
      <Typography my={2} fontWeight="bold" align="center">
        W naszej agroturystyce znajdują się:
      </Typography>
      <Timeline
        sx={{
          mx: { xs: -4, md: 0 },
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          [`& .${timelineContentClasses.root}`]: {
            pt: 2.5,
            pb: 4,
            fontSize: { xs: '0.75rem', md: '1rem' },
            textAlign: 'justify',
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<Bed />} />
            <Connector />
          </TimelineSeparator>
          <TimelineContent>
            Łącznie 8 miejsc noclegowych, rozłożonych na trzy pokoje. Każdy pokój posiada własną, nowoczesną łazienkę z
            prysznicem. Dodatkowo goście zastaną w pokojach ręczniki oraz świeżo przygotowane posłanie. W każdym pokoju
            znajduje się telewizor z internetem oraz dostęp do darmowego Wi-Fi. W wyposażeniu agroturystyki znajduje się
            również pralka.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<Kitchen />} />
            <Connector />
          </TimelineSeparator>
          <TimelineContent>
            Nowoczesna kuchnia, wyposażona w lodówkę z zamrażarką, czajnik, piekarnik, płytę indukcyjną oraz
            mikrofalówkę. Dodatkowo do dyspozycji gości są naczynia, sztućce oraz całe najważniejsze wyposażenie kuchni.
            Zawsze znajdziecie tam również kawę i herbatę. Kuchnia połączona jest z jadalnią, gdzie znajdują się krzesła
            oraz stół. Idealne miejsce do wspólnych posiłków oraz wieczornych rozmów przy kubku herbaty.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<LocalFlorist />} />
            <Connector />
          </TimelineSeparator>
          <TimelineContent>
            Ogród, który jest prawdziwą oazą spokoju i piękna, który zaprasza do relaksu i kontaktu z naturą. Otoczony
            malowniczymi kwiatami i bujnymi krzewami, jest idealnym miejscem na wypoczynek.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<Nightlife />} />
            <Connector />
          </TimelineSeparator>
          <TimelineContent>
            Taras znajdujący się w ogrodzie to doskonałe miejsce na poranną kawę lub wieczorne lampki wina. Klimatyczne
            oświetlenie nadaje romantyczny nastrój, a zadaszenie pozwala cieszyć się naturą nawet w deszczowe dni.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<Deck />} />
            <Connector />
          </TimelineSeparator>
          <TimelineContent>
            Altanka w klimacie leśnym, z dużym stołem i ławkami, która jest dobrym schronieniem od słońca w upalne dni,
            jak i przed deszczem w te bardziej pochmurne. Cudowne miejsce do spędzania czasu z książką, planszówkami lub
            podczas ciekawych rozmów.
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <Dot icon={<LocalParking />} />
          </TimelineSeparator>
          <TimelineContent>Prywatny parking na zamkniętym terenie.</TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  )
}
