import { createTheme } from '@mui/material/styles'
import { plPL } from '@mui/material/locale'
import { responsiveFontSizes } from '@mui/material'

const theme = responsiveFontSizes(
  createTheme(
    {
      typography: {
        fontFamily: "'Montserrat', sans-serif",
        fontSize: 16,
        htmlFontSize: 16,
      },
      palette: {
        primary: {
          main: '#106215',
        },
        secondary: {
          main: '#45A19A',
        },
        divider: '#D6AB06',
      },
    },
    plPL
  )
)

export default theme
