import Box from '@mui/material/Box'
import { UProguLasuText } from 'app/assets/icons/UProguLasuText'
type Props = {
  inverted?: boolean
}
export const Logo = ({ inverted }: Props) => {
  const fontColor = inverted ? 'black' : 'white'
  const textShadow = inverted ? '1px 1px 1px white' : '1px 1px 1px black'
  return (
    <Box display={'flex'}>
      <UProguLasuText
        fill={fontColor}
        sx={{ fontSize: { xs: '12rem !important', sm: '14rem', lg: '16rem !important' }, textShadow }}
      />
    </Box>
  )
}
