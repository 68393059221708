import Castle from '@mui/icons-material/Castle'
import Park from '@mui/icons-material/Park'
import Water from '@mui/icons-material/Water'
import BikeScooter from '@mui/icons-material/BikeScooter'
import Kayaking from '@mui/icons-material/Kayaking'
import Train from '@mui/icons-material/Train'
import NaturePeople from '@mui/icons-material/NaturePeople'
import Forest from '@mui/icons-material/Forest'
import HotTub from '@mui/icons-material/HotTub'
import Egg from '@mui/icons-material/Egg'
import Phishing from '@mui/icons-material/Phishing'
import PedalBike from '@mui/icons-material/PedalBike'
import OutdoorGrill from '@mui/icons-material/OutdoorGrill'
import Fireplace from '@mui/icons-material/Fireplace'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { images } from 'app/assets/images'
import { MediaCarousel } from 'app/layout/Carousel/MediaCarousel'
import { ReactNode } from 'react'
import { useIsMobile } from 'utils/hooks/useIsMobile'
import { useSetTitle } from 'utils/hooks/useSetTitle'
import { standardBoxProps } from 'utils/styles/standardBoxProps'

export const AttractionsPage = () => {
  useSetTitle()
  const isMobile = useIsMobile()
  const items1 = images.outside.map((room) => ({ url: room.src, alt: room.alt }))
  const items2 = images.attractionsInRegion.map((room) => ({ url: room.src, alt: room.alt }))

  const Dot = ({ icon }: { icon?: ReactNode }) => (
    <TimelineDot sx={{ backgroundColor: (theme) => theme.palette.divider }} children={icon} />
  )
  const Connector = () => <TimelineConnector sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }} />

  return (
    <>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '100vh',
          background: '#fff',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Box display="grid">
            <Typography variant="h4" align="center">
              Atrakcje naszej agroturystyki:
            </Typography>
          </Box>
          <Grid container justifyContent="space-between" alignItems="center" spacing={{ xs: 2, md: 16 }}>
            <Grid item xs={12} md={6}>
              <Timeline
                sx={{
                  mx: { xs: -4, md: 0 },
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  [`& .${timelineContentClasses.root}`]: {
                    pt: 2.5,
                    pb: 4,
                    fontSize: { xs: '0.75rem', md: '1rem' },
                    textAlign: 'justify',
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Fireplace />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>Miejsce ogniskowe</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<OutdoorGrill />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>Grill</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<PedalBike />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>Wypożyczenie rowerów</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Phishing />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>Udostępnienie sprzętu do łowienia ryb na prywatnym stawie</TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Egg />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    Poczęstunek swojskimi jajkami od szczęśliwych kurek oraz sezonowymi owocami i warzywami z naszego
                    ogródka
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<HotTub />} />
                  </TimelineSeparator>
                  <TimelineContent>
                    Fińska Balia z jacuzzi (Minimum 2h – 200zł, następne godziny – 70zł) Nasi goście mogą dodatkowo
                    wynająć luksusową balię fińską, gdzie można zanurzyć się w ciepłej wodzie niezależnie od pory roku i
                    rozkoszować się hydromasażem. To doskonały sposób na relaks i odprężenie po dniu pełnym aktywności.
                    Banię wynajmujemy zawsze na wyłączność, korzystać z niej można jednak w kilka osób.
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Grid>
            <Grid item xs={12} md={6}>
              <MediaCarousel items={items1} />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '100vh',
          background: '#f2f4ec',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Box display="grid">
            <Typography variant="h4" align="center">
              Atrakcje w regionie:
            </Typography>
          </Box>
          <Grid container justifyContent="space-between" alignItems="flex-start" spacing={{ xs: 2, md: 16 }}>
            {!isMobile && (
              <Grid item xs={12} md={6} sx={{ mt: 4 }}>
                <MediaCarousel items={items2} />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Timeline
                sx={{
                  mx: { xs: -4, md: 0 },
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                  [`& .${timelineContentClasses.root}`]: {
                    pt: 2.5,
                    pb: 4,
                    fontSize: { xs: '0.75rem', md: '1rem' },
                    textAlign: 'justify',
                  },
                }}
              >
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Forest />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Puszcza Białowieska:
                    </Typography>{' '}
                    To oczywiście główna atrakcja regionu. Puszcza Białowieska to jeden z ostatnich i największych
                    obszarów dzikiej puszczy w Europie. To idealne miejsce do obserwacji dzikiej przyrody, w tym żubrów,
                    jeleni, łosi i różnorodnych gatunków ptaków. Ścieżki dydaktyczne i wieża widokowa pozwalają na
                    bliski kontakt z naturą.
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Castle />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Pałac w Białowieży:
                    </Typography>{' '}
                    To historyczny pałac, który był letnią rezydencją carską. Dziś mieści muzeum poświęcone historii
                    Puszczy Białowieskiej i rosyjskim carom. Można również zwiedzać park pałacowy.
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Park />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Białowieski Park Narodowy:
                    </Typography>{' '}
                    To obszar chroniony, który obejmuje znaczną część Puszczy Białowieskiej. Park oferuje wiele szlaków
                    turystycznych i możliwości obserwacji dzikiej przyrody. Warto skorzystać z usług przewodnika, aby
                    poznać tajniki tego wyjątkowego ekosystemu.
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<PedalBike />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Wyjątkowe trasy rowerowe oraz ścieżki na klimatyczne spacery
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Water />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Aquapark w Hajnówce:
                    </Typography>{' '}
                    To nowoczesny kompleks basenowy, będący świetną atrakcją dla całej rodziny.
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<BikeScooter />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Drezyny rowerowe
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Kayaking />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Topiło:
                    </Typography>{' '}
                    malownicze jezioro w Puszczy Białowieskiej
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<Train />} />
                    <Connector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Kolejka wąskotorowa
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <Dot icon={<NaturePeople />} />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography fontWeight="bold" textAlign="left">
                      Możliwość zbierania grzybów oraz owoców leśnych w pobliskich lasach
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Grid>
            {isMobile && (
              <Grid item xs={12} md={6}>
                <MediaCarousel items={items2} />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  )
}
