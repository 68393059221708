import Close from '@mui/icons-material/Close'
import Home from '@mui/icons-material/Home'
import Mail from '@mui/icons-material/Mail'
import Phone from '@mui/icons-material/Phone'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar, { appBarClasses } from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'
import Dialog from '@mui/material/Dialog'
import { Outlet } from '@tanstack/react-router'
import { UProguLasu } from 'app/assets/icons/UProguLasu'
import { Navbar } from 'app/layout/nav/Navbar'
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import { useIsMobile } from 'utils/hooks/useIsMobile'
import { standardBoxProps } from 'utils/styles/standardBoxProps'
import BackgroundVideo from '../assets/videos/background.mp4'
import garden from '../assets/images/outside/altanka-2.jpg'

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>
  },
  ref: Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />
})

export const Layout = () => {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isOpenReservationDialog, setIsOpenReservationDialog] = useState(false)
  const isMobile = useIsMobile()
  const handleScroll = () => {
    setIsScrolled(window.scrollY > window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box sx={{ maxWidth: '100vw' }}>
      <Box
        sx={{
          background: 'black',
          maxWidth: '100vw',
          height: '100vh',
          '& #myVideo': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '100vw',
            maxHeight: '100vh',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        }}
      >
        <video autoPlay muted loop id="myVideo">
          <source src={BackgroundVideo} type="video/mp4" />
        </video>
        <AppBar
          position={'absolute'}
          elevation={0}
          sx={{
            height: (t) => t.spacing(10),
            [`&.${appBarClasses.root}`]: {
              background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, 65%, rgba(0, 0, 0, 0.0) 100%)',
              borderBottom: '1px solid rgba(214, 171, 6,0.3)',
            },
          }}
        >
          <Navbar />
        </AppBar>
        <Slide in={isScrolled} direction="down" timeout={600}>
          <AppBar position={'fixed'} color="default" sx={{ height: (t) => t.spacing(10) }}>
            <Navbar inverted />
          </AppBar>
        </Slide>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
          textShadow: '1px 1px 1px black',
          backgroundColor: isMobile ? 'rgba(0,0,0,0.4)' : undefined,
        }}
      >
        <UProguLasu
          fill="#FFF"
          sx={{
            fontSize: { xs: '18rem', sm: '20rem', md: '24rem', lg: '30rem', xl: '40rem' },
          }}
        />
        <Box>
          <Button variant="contained" size="large" onClick={() => setIsOpenReservationDialog(true)}>
            <Typography component="span" fontWeight="bold">
              Zarezerwuj pokój
            </Typography>
          </Button>
          <Dialog
            open={isOpenReservationDialog}
            onClose={() => setIsOpenReservationDialog(false)}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={isMobile}
            maxWidth="md"
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255,255,255,0.9)',
                color: 'black',
                textShadow: 'none',
                py: 4,
                px: { xs: 4, sm: 4, lg: 8 },
                borderRadius: 2,
                position: 'relative',
                width: { xs: '100%', md: '600px' },
              }}
            >
              <IconButton
                aria-label="close"
                onClick={() => setIsOpenReservationDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <Typography variant="h5" align="center">
                Agroturystyka
              </Typography>
              <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                "U progu lasu"
              </Typography>
              <Box display="grid" gridTemplateColumns="auto 1fr" rowGap={1} columnGap={1} alignContent="center">
                <Box>
                  <Home />
                </Box>
                <Box>
                  <Link
                    variant="body2"
                    href="https://maps.app.goo.gl/zzgbepWSYh81Hc3C7"
                    target="_blank"
                    underline="hover"
                  >
                    <Typography>Orzeszkowo 22</Typography>
                    <Typography>17-200 Hajnówka</Typography>
                  </Link>
                </Box>
                <Box>
                  <Phone />
                </Box>
                <Box>
                  <Link variant="body2" href="tel:+48661424666" target="_blank" underline="hover">
                    661424666
                  </Link>
                </Box>
                <Box>
                  <Mail />
                </Box>
                <Box>
                  <Link variant="body2" href="mailto:pokoje.uprogulasu@wp.pl" target="_blank" underline="hover">
                    pokoje.uprogulasu@wp.pl
                  </Link>
                </Box>
              </Box>
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9674.628856569552!2d23.53408752771455!3d52.68422757681725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4720415ffc115b93%3A0xf4f58a95d411776b!2sAgroturystyka%20U%20progu%20lasu!5e0!3m2!1spl!2spl!4v1718479889370!5m2!1spl!2spl"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="google maps"
                ></iframe>
              </Box>
              <Typography align="center" sx={{ mt: 2 }}>
                Zapraszamy do kontaktu!
              </Typography>
            </Box>
          </Dialog>
        </Box>
      </Box>
      <Outlet />
      <Box
        sx={(theme) => ({
          ...standardBoxProps,
          height: '100%',
          minHeight: '50vh',
          background: '#fff',
          backgroundImage: `url(${garden})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: theme.palette.common.black,
        })}
      >
        <Container maxWidth="xl">
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9674.628856569552!2d23.53408752771455!3d52.68422757681725!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4720415ffc115b93%3A0xf4f58a95d411776b!2sAgroturystyka%20U%20progu%20lasu!5e0!3m2!1spl!2spl!4v1718479889370!5m2!1spl!2spl"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen={false}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="google maps"
                ></iframe>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper sx={{ p: 2 }}>
                <Typography align="center">Zapraszamy do kontaktu</Typography>
                <Typography variant="h5" align="center">
                  Agroturystyka
                </Typography>
                <Typography variant="h5" align="center" sx={{ mb: 2 }}>
                  "U progu lasu"
                </Typography>
                <Box display="grid" gridTemplateColumns="auto 1fr" rowGap={1} columnGap={1} alignContent="center">
                  <Box>
                    <Home />
                  </Box>
                  <Box>
                    <Link
                      variant="body2"
                      href="https://maps.app.goo.gl/zzgbepWSYh81Hc3C7"
                      target="_blank"
                      underline="hover"
                    >
                      <Typography>Orzeszkowo 22</Typography>
                      <Typography>17-200 Hajnówka</Typography>
                    </Link>
                  </Box>
                  <Box>
                    <Phone />
                  </Box>
                  <Box>
                    <Link variant="body2" href="tel:+48661424666" target="_blank" underline="hover">
                      661424666
                    </Link>
                  </Box>
                  <Box>
                    <Mail />
                  </Box>
                  <Box>
                    <Link variant="body2" href="mailto:pokoje.uprogulasu@wp.pl" target="_blank" underline="hover">
                      pokoje.uprogulasu@wp.pl
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}
