import { createRootRoute, createRoute, createRouter } from '@tanstack/react-router'
import { Layout } from 'app/layout/Layout'
import { AttractionsPage } from 'pages/AttractionsPage'
import { GalleryPage } from 'pages/GalleryPage'
import { HomePage } from 'pages/HomePage'
import { RegulationsPage } from 'pages/RegulationsPage'
import { RoomsPage } from 'pages/RoomsPage'
import React from 'react'

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      )

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: HomePage,
})

const roomsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/pokoje',
  component: RoomsPage,
})

const attractionsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/atrakcje',
  component: AttractionsPage,
})

const galleryRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/galeria',
  component: GalleryPage,
})

const regulationsRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/regulamin',
  component: RegulationsPage,
})

const rootRoute = createRootRoute({
  component: () => (
    <>
      <Layout />
      <TanStackRouterDevtools />
    </>
  ),
})

const routeTree = rootRoute.addChildren([indexRoute, roomsRoute, attractionsRoute, galleryRoute, regulationsRoute])

export const router2 = createRouter({ routeTree })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router2
  }
}
